var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Article',{attrs:{"title":"PhysMin","year":"2020","repos":[{
           text: 'android physmin',
           href: 'http://github.com/IgorKhramtsov/physmin-android'
         },{
           text: 'serverless physmin',
           href: 'http://github.com/IgorKhramtsov/physmin-functions'
         },{
           text: 'service website physmin',
           href: 'http://github.com/IgorKhramtsov/physmin-web'
         }]}},[_c('p',{staticClass:"col-md-10"},[_vm._v(" My bachelor project, I've made with my group mate. Idea of app was inspired by Duolingo, with predominance of practice above the theory. Our physics professor gave us theoretical materials, to built app upon of them. The main concept was in a large amount of precedural generated task cases. So for this we create Typescript server on Firebase. To make sure, the generated tasks are correct (and this is very important in such application) unit-tests were written, and executed on every push via GitLab CI. ")]),_c('div',{staticClass:"col-md-10"},[_c('img',{staticClass:"appgif",attrs:{"src":require("../../assets/physmin.gif"),"alt":"Process of working with PhysMin app"}})]),_c('p',{staticClass:"col-md-10"},[_vm._v(" Android app is written on Kotlin, contains some custom views and layouts. Also Lottie animation used on end of test. To keep user data, Firebase Authentication with Firestore was used. On wrong answer, task moves to end of queue, so user have to complete all tasks correct with infinite number of tries. For debug purpose I implement simple helper which appear on top of the screen, to tell if this answer is correct or not. Figma was used for UI designing. ")]),_c('div',{staticClass:"col-md-10"},[_c('MacOsWindow',{attrs:{"title":"Service website"}},[_c('video',{staticClass:"gif",attrs:{"src":require("../../assets/physmin-web.mp4"),"alt":"Service website functionality preview","autoplay":"true","loop":"true","playsinline":"true","type":"video/mp4"}})])],1),_c('p',{staticClass:"col-md-10"},[_vm._v(" To make easier process of creating test sequences for each subject, service website was created with react and Material UI on firebase hosting. This site is operate with firestore database, for building configs, based on which the tests will be built on user request. ")])])}
var staticRenderFns = []

export { render, staticRenderFns }